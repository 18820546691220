import React from 'react';

import DefaultLayout from '../layouts/DefaultLayout';
import DefaultHelmet from '../seo/DefaultHelmet';

import beaconWidgetIds from '../settings/beacon-widget-ids';
import useBeaconWidget from '../hooks/useBeaconWidget';
import PageHeader from '../components/PageHeader';
import ProductFeedbackForm from '../components/ProductFeedback/ProductFeedbackForm';

const ProductFeedback = ({pageContext}) => {
  const {
      pageHeader,
      acfProductFeedback,
      acfMetaData,
  } = pageContext;

  const pageTitle = acfMetaData?.title || 'Product Feedback';
  const pageDescription = acfMetaData?.description || 'Leave your feedback about using our product';
  const pageKeywords = acfMetaData?.keywords || 'web development, UTD, eCommerce, design, development, marketing, growth optimization, customer experience, Shopify, Shopify Shops, Shopify Stores, Shopify APPs, Shopify Themes, brand development, business growth, premium services, innovative technologies';

  useBeaconWidget(beaconWidgetIds.GENERAL);

  return (
    <DefaultLayout className="dark-theme">
      <DefaultHelmet title={pageTitle} description={pageDescription} keywords={pageKeywords}/>

      <PageHeader {...pageHeader} isGeneral={true}/>

      {acfProductFeedback
        && acfProductFeedback.form
        && acfProductFeedback.form.formId
        && (
        <section className="product-feedback container">
          <ProductFeedbackForm form={acfProductFeedback.form}/>
        </section>
      )}
    </DefaultLayout>
  );
};

export default ProductFeedback;
