import React, {useCallback, useEffect, useRef, useState} from 'react';
import IconSuccess from '../../icons/IconSuccess';

const ProductFeedbackForm = ({form}) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('Thank you for your message. It has been sent.');
  const [invalidFields, setInvalidFields] = useState({});
  const [files, setFiles] = useState([]);
  const [initValues, setInitValues] = useState({});
  const fileRef = useRef(null);

  const handleChange = useCallback((event) => {
    setFiles([...files, ...event.target.files]);
  }, [files]);

  const handleRemove = useCallback((name) => {
    const filteredList = files.filter(file => file.name !== name);
    const dataTransfer = new DataTransfer();
    for (let i = 0; i < fileRef.current.files.length; i++) {
      if (fileRef.current.files[i].name !== name) {
        dataTransfer.items.add(fileRef.current.files[i]);
      }
    }
    fileRef.current.files = dataTransfer.files;

    setFiles(filteredList.length ? filteredList : []);
  }, [files]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();

    const targetForm = event.target;
    const formData = new FormData(targetForm);

    try {
      const response = await fetch(`/wp-json/contact-form-7/v1/contact-forms/${form.formId}/feedback`, {
        body: formData,
        method: 'POST',
      });
      const responseJSON = await response.json();

      if (!responseJSON.status) {
        console.log(`Error: ${responseJSON.message}`);
        throw new Error('Server error');
      }

      setSubmitMessage(responseJSON?.message);

      if (responseJSON.status !== 'mail_sent') {
        const invalidMessages = responseJSON.invalid_fields.reduce((obj, currentValue) => {
          obj[currentValue.into.replace('span.wpcf7-form-control-wrap.', '')] = currentValue.message;

          return obj;
        }, {});

        setInvalidFields(invalidMessages);
        return;
      }

      setInvalidFields({});
      setIsSubmit(true);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }, []);

  const inputMarkdown = useCallback((
    field,
    name,
    initValue,
    type = 'text',
  ) => {
    return (
      <>
        {field.show && (
          <>
            <label className="ctf__label ctf__label--half">
              <p className="ctf__label-text">
                <span>
                  {field.label}

                  {field.isRequired && (
                    <span className="ctf__required-star">*</span>
                  )}
                </span>
              </p>

              <input
                className="ctf__input"
                type={type}
                placeholder={field.placeholder}
                required={field.isRequired}
                name={name}
                defaultValue={initValue}
              />
            </label>

            {
              invalidFields[name] && (
                <div className="ctf__error-message">
                  <p>{invalidFields[name]}</p>
                </div>
              )
            }
          </>
        )}
      </>
    );
  }, [form]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const params = new URL(window.location.href).searchParams;
    const storeName = params.get('store-name');
    const email = params.get('email');
    const productName = params.get('product-name');
    setInitValues({
      storeName,
      email,
      productName,
    });
  }, []);

  return (
    <>
      {isSubmit
        ? (
          <div className="product-feedback__success contact-tabs__tab contact-tabs__tab--success">
            <IconSuccess/>
            <p className="contact-tabs__success">{submitMessage}</p>
          </div>
        )
        : (
          <form onSubmit={handleSubmit}>
            <div className="ctf">
              <h2 className="ctf__heading">
                {form.label}
              </h2>

              {form.description
                ? (
                  <p className="ctf__description">
                    {form.description}
                  </p>
                )
                : null
              }

              {form.storeName && inputMarkdown(form.storeName, 'store_name', initValues.storeName)}
              {form.email && inputMarkdown(form.email, 'email', initValues.email,'email')}
              {form.productName && inputMarkdown(form.productName, 'product_name', initValues.productName)}

              {form.message && form.message.show && (
                <label className="ctf__label ctf__label--full">
                  <p className="ctf__label-text">
                    <span>
                      {form.message.label}

                      {form.message.isRequired && (
                        <span className="ctf__required-star">*</span>
                      )}
                    </span>
                  </p>

                  <textarea
                    className="ctf__input"
                    placeholder={form.message.placeholder}
                    required={form.message.isRequired}
                    rows={5}
                    name="message"
                  />
                </label>
              )}

              {files && files.length
                ? (
                  <div className="ctf__files-list">
                    {files.map((file, index) => (
                      <div className="ctf__file-row" key={index}>
                        <span>{file.name}</span>

                        <button
                          className="ctf__remove"
                          onClick={() => handleRemove(file.name)}
                          type="button"
                          aria-label="Remove file"
                        />
                      </div>
                    ))}
                  </div>
                )
                : null
              }

              <div className="ctf__actions">
                {form.addFiles && form.addFiles.show && (
                  <>
                    <label className="ctf__add-input">
                      {form.addFiles.label || 'Attach File'}

                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png, .mp4, .avi, .webm, .webp, .pdf"
                        onChange={handleChange}
                        ref={fileRef}
                        // The messaging plugin does not support processing multiple files.
                        // However, from the front, everything is ready
                        multiple={false}
                        name="file"
                      />
                    </label>

                    <button
                      aria-label="Attach a file to your message"
                      className="ctf__add button button--transparent button--large"
                      type="button"
                      disabled={files && files.length}
                      onClick={() => fileRef.current.click()}
                    >
                      {form.addFiles.label || 'Attach File'}
                    </button>

                    {files && invalidFields.file && (
                      <p className="ctf__error-message">{invalidFields.file}</p>
                    )}
                  </>
                )}

                <button
                  className="ctf__submit button button--large"
                  type="submit"
                >
                  {form.sendButton.label || 'Send'}
                </button>
              </div>
            </div>
          </form>
        )
      }
    </>
  );
};

export default ProductFeedbackForm;